<template>
  <b-row>
    <b-col>
      <b-link  @click="$router.push({name: 'my_work', params: { section: 'networks' } })">
        <feather-icon icon="ChevronLeftIcon"></feather-icon>
        {{$t('dashboard.backTo')}}
      </b-link>
      <b-row class="border-top mt-2">
        <view-connections-card 
          class="col-12 col-md-6 col-lg-4 p-2" 
          :networks="profile.networks"
          @update_parent="getProfile"
        /> 
        <b-col class="col-12 col-md-6 col-lg-8">
          <div class="card-analytics-profile-net p-3 border-left border-right">
            <div>
              <b-row class="d-flex align-items-center justify-content-between flex-wrap">
                <div class="d-flex align-items-center">
                  <b-avatar badge-variant="light" size="80px" class="mr-2">
                    <template #badge>
                      <span
                        :class="`${utils.getClassNetworkSpan(network_selected.network)} bg-white`"
                        :style="`border:1px solid #${utils.getColor(network_selected.network)}`"
                      ></span>
                    </template>
                  </b-avatar>
                  <div>
                    <h2>{{network_selected.username}}</h2>
                    <div class="d-flex align-items-center">
                      <feather-icon icon="LinkIcon" class="mr-05"></feather-icon>
                      <b-link class="url-username-connection"
                        :href="getUrlNetwork(network_selected)"
                        target="_blank"
                      >
                        {{getUrlNetwork(network_selected)}}
                      </b-link>
                    </div>
                  </div>
                </div>

                <b-button class="d-block p-0" variant="flat-secondary">
                  <b-link :href="getUrlNetwork(network_selected)" target="_blank" class="d-block p-1 url-username-connection">
                    View on {{utils.capitalize(network_selected.network)}}
                  </b-link>
                </b-button>
              </b-row>

              <b-tabs class="mt-2">
                <b-tab title="Overview" active>
                  <b-row>
                    <h4 class="col-12 my-2">Analytics</h4>
                    <b-col>
                      <div class="border-box-analytics">
                        <p>{{$t('campaigns.followers')}}</p>
                        <strong>{{network_selected.followers !== 0 ? network_selected.followers : '--'}}</strong >
                      </div>
                    </b-col>
                    <b-col>
                      <div class="border-box-analytics">
                        <p>Engagement rate</p>
                        <strong>{{network_selected.er !== null ? `${network_selected.er} %` : '--'}}</strong >
                      </div>
                    </b-col>
                  </b-row>

                </b-tab>
                <b-tab title="Analytics" disabled></b-tab>
              </b-tabs>

            </div>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BLink,
  BAvatar,
  BButton,
  BTabs,
  BTab
} from 'bootstrap-vue';
import utils from '@/libs/utils';
import service_others from '@/services/others';
import viewConnectionsCard from '@/views/components/creator/viewConnectionsCard.vue';


export default {
  name: 'detailConnection',
  components: {
    BRow,
    BCol,
    BLink,
    BAvatar,
    BButton,
    BTabs,
    BTab,
    viewConnectionsCard
  },
  data() {
    return {
      utils,
      profile: {},
      network_selected: {},
    }
  },
  created() {
    this.getProfile();
  },
  methods: {
    getProfile() {
      const user = JSON.parse(localStorage.userData);
      service_others.getProfile().then((response) => {
        this.profile = response.user;
        this.network_selected = this.profile.networks.find((item) => item.username === this.$route.params.username && item.network === this.$route.params.network);
        utils.setuserData(response, user.ability);
      });
    },
    getUrlNetwork(net) {
      return utils.getURL(net.network, net.username, (net.network === 'youtube'))
    }
  }
}
</script>
<style>
.mr-05 {
  margin-right: 0.5em;
}
.url-username-connection {
  color: #b1b1b1;
}
.card-analytics-profile-net {
  height: 100%;
  background-color: white;
}
.border-box-analytics {
  border: 1px solid #eaeaea;
  border-radius: 1em;
  padding: 0.5em;
  box-shadow: 0rem 0.375rem 0.75rem rgb(108 117 125 / 8%);
}
</style>
